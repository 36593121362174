<template lang="pug">
.sharesight-integration-page
  section.hero.is-primary.is-bold.is-medium
    .hero-body
      .container.has-text-centered
        h1.title.is-spaced Connect your Sharesight Account
        h2.subtitle.small-para
          | We believe in using the right tool for the job. That's why we're partnered
          | with Sharesight.
        h2.subtitle.small-para.is-hidden-mobile
          | Sharesight provide a best in class method to track your
          | day to day individual share holdings and stay on top of your tax liabilities.
        h2.subtitle.small-para.is-hidden-mobile
          | We provide the easiest way to keep track of all your assets &
          | liabilities in one place.
        br.is-hidden-mobile
        router-link.button.is-white.is-large(v-if="isUserLoggedIn" :to="{name: 'integrations'}")
          strong Connect now
        router-link.button.is-white.is-large(v-if="!isUserLoggedIn" :to="{name: 'sign_up'}")
          strong Sign up to connect

  section.container.section
    .columns
      .column.is-10.is-offset-1.has-text-centered
        h2.title Import your balance
        br
        p.subtitle
          | Connecting your Sharesight account will let you import the
          | value of a Sharesight portfolio into a net worth entry, whenever
          | you want, with a single click.
        img.sharesight-import-wide(alt="Import from Sharesight" src="@/assets/img/import-wide.png")
        img.sharesight-import-small(alt="Import from Sharesight" src="@/assets/img/import-small.png")
        p.subtitle
          | It's that simple.

  section.container.section#about-sharesight
    .columns
      .column.is-8.is-offset-2.has-text-centered
        h2.title.sharesight-title
          | About&nbsp;
          img.sharesight-logo(alt="Sharesight" src="@/assets/img/sharesight.svg")
        br
        p.subtitle
          | Sharesight provides price data, tax reports & holding
          | tracking for over&nbsp;
          a(href="https://help.sharesight.com/au/supported-stock-exchanges-and-managed-funds/" target="_blank")
            | 28 international stock exchanges and mutual
            | funds.
          | &nbsp;It's hands down the simplest way to keep track of any publicly
          | traded shares that you own and a blessing when it comes to tax
          | time.
        p.subtitle
          | Sharesight is free to use for a single portfolio with up to ten holdings.
        a.button.is-primary.is-medium.is-bold(href="https://www.sharesight.com/" target="_blank")
          | Find out more
  about-footer
</template>

<script>
import { mapGetters } from 'vuex'
import AboutFooter from '@/components/AboutFooter'

export default {
  components: {
    AboutFooter
  },
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn'])
  },
  methods: {},
  metaInfo: {
    title: 'Connect your Sharesight Account',
    meta: [
      {
        name: 'description',
        content: `Use Sharesight together with nutworth to make tracking your assets a breeze.`
      }
    ]
  }
}
</script>

<style lang="sass" scoped>
.highlight-text
  background: #b2e4f3
  padding: 0 0.25rem
  color: #333
.small-para
  margin-left: auto
  margin-right: auto
.sharesight-title
  display: flex
  align-items: baseline
  justify-content: center
  img.sharesight-logo
    position: relative
    top: 10px
    margin-left: 0.75rem
.sharesight-import-wide
  display: none
  @media screen and (min-width: 768px)
    display: inline-block
.sharesight-import-small
  @media screen and (min-width: 768px)
    display: none
.sharesight-import-wide, .sharesight-import-small
  max-width: 100%
  background: white
  padding: 1rem
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
  margin-bottom: 1.5rem
</style>
